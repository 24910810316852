import { useState, useEffect } from 'react'
import firestore, { storage } from "../../configurations/firebase.js"
import { doc, getDoc, getDocs, collection, query, where, limit, orderBy } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage"
import { Helmet } from "react-helmet"

import TutorProfileHeaderSection from "./TutorProfileHeaderSection"
import TutorProfileOverviewSection from "./TutorProfileOverviewSection"
import TutorProfileAboutSection from "./TutorProfileAboutSection"
import TutorProfileSubjectSection from "./TutorProfileSubjectSection"
import TutorProfileReviewSection from "./TutorProfileReviewSection"
import TutorProfileShareModal from "./TutorProfileShareModal"

const TutorProfile = (props) => {
  const [showShareSheet, setShowShareSheet] = useState(false)
  const [profile, setProfile] = useState()

  const handleShowShareSheet = () => {
    setShowShareSheet(true)
  }

  const handleShareSheetClose = () => {
    setShowShareSheet(false)
  }

  const handleOpenRequestLessonLink = () => {
    var link = `${window.location.origin}/links/request-lesson?id=${profile.id}`
    window.open(link, "_blank")
  }

  const fetchProfile = async () => {
    const tutorID = props.match.params.tutorID

    console.log("loading profile")

    const tutorDoc = await getDoc(doc(firestore, "tutors", tutorID))
    if (!tutorDoc.exists) {
      return
    }

    const imageRef = ref(storage, `users/${tutorID}/profile.jpg`)
    const imageURL = await getDownloadURL(imageRef)

    const tutor = tutorDoc.data()
    const entries = await getDocs(query(collection(firestore, "entries"), where("tutorID", "==", tutorID))) ?? []
    const reviews = await getDocs(query(collection(firestore, "reviews"), where("revieweeID", "==", tutorID)), orderBy("createdAt", "desc"), limit(5)) ?? []

    const profile = {
      id: tutorDoc.id,
      info: {
        name: tutor.displayName,
        school: tutor.schoolName,
        avatar: imageURL
      },
      share: {
        url: window.location.href,
        name: tutor.displayName,
        school: tutor.schoolName,
        avatar: imageURL
      },
      overview: {
        averageRating: tutor.reviewCount < 1 ? "No reviews" : `${(tutor.rating / tutor.reviewCount) * 5} (${tutor.reviewCount} reviews)`,
        reviewCount: tutor.reviewCount,
        minutesTutored: tutor.minuteCount < 1 ? "No lessons tutored" : `${tutor.minuteCount} mins tutored`,
        studentsTutored: tutor.studentCount < 1 ? "No students tutored" : `${tutor.studentCount} students tutored`
      },
      about: {
        bio: tutor.bio
      },
      tutor: {
        name: tutor.displayName,
        imageURL: imageURL,
        schoolName: tutor.schoolName,
        bio: tutor.bio,
        rating: tutor.reviewCount < 1 ? "No reviews" : `${(tutor.rating / tutor.reviewCount) * 5} (${tutor.reviewCount} reviews)`,
        reviewCount: tutor.reviewCount,
        durationTutored: tutor.minuteCount < 1 ? "No lessons tutored" : `${tutor.minuteCount} mins tutored`,
        studentsTutored: tutor.studentCount < 1 ? "No students tutored" : `${tutor.studentCount} students tutored`,
      },
      subjects: entries.docs.map((entryDocument) => {
        const entry = entryDocument.data()
        return {
          id: entryDocument.id,
          title: entry.subjectTitle,
          number: entry.subjectNumber,
          professor: entry.professorName,
          description: entry.description,
          hourlyRate: `${entry.hourlyRate}`
        }
      }),
      reviews: reviews.docs.map((reviewDocument) => {
        const review = reviewDocument.data()
        return {
          id: reviewDocument.id,
          reviewer: review.reviewerName,
          subject: review.subjectName,
          body: review.body,
          rating: review.rating * 5
        }
      })
    }

    setProfile(profile)
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  if (profile) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white px-4 py-12">
        <Helmet
          title={`Peer | ${profile.info.name}`}
          htmlAttributes={{ lang: "en" }}
          meta={[
            {
              name: "description",
              content: `Request a lesson with ${profile.info.name}`,
            },
            {
              property: "og:title",
              content: `${profile.info.name}`
            },
            {
              property: "og:description",
              content: `Request a lesson with ${profile.info.name}`
            },
            {
              property: "og:url",
              content: `${profile.share.url}`
            },
            {
              property: "og:type",
              content: "website"
            }
          ]}
        />
        <TutorProfileShareModal isOpen={showShareSheet} share={profile.share} actions={{ handleShareSheetClose }} />
        <div className="divide-y-2 max-w-lg w-full">
          <div className="py-6">
            <TutorProfileHeaderSection info={profile.info} actions={{ handleOpenRequestLessonLink, handleShowShareSheet }} />
          </div>
          <div className="py-6">
            <TutorProfileOverviewSection overview={profile.overview} />
          </div>
          {profile.about.bio.length > 0 &&
            <div className="py-6">
              <TutorProfileAboutSection about={profile.about} />
            </div>
          }
          {profile.subjects.length > 0 &&
            <div className="py-6">
              <TutorProfileSubjectSection subjects={profile.subjects} />
            </div>
          }
          {profile.reviews.length > 0 &&
            <div className="py-6">
              <TutorProfileReviewSection reviews={profile.reviews} />
            </div>
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className="min-h-screen">
        <Helmet
          title="Peer | Tutor profile"
          htmlAttributes={{ lang: "en" }}
          meta={[
            {
              name: "description",
              content: `Request a lesson with a tutor`,
            },
            {
              property: "og:title",
              content: `Tutor profile`
            },
            {
              property: "og:description",
              content: `Request a lesson with a tutor`
            },
            {
              property: "og:url",
              content: `${window.location.href}`
            },
            {
              property: "og:type",
              content: "website"
            }
          ]}
        />
      </div>
    )
  }
}

export default TutorProfile
