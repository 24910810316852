import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const TutorProfileShareModal = (props) => {
  const [showCopyLinkSuccess, setShowCopyLinkSuccess] = useState(false)
  const cancelButtonRef = useRef(null)

  const handleShowCopyLinkSuccess = () => {
    setShowCopyLinkSuccess(true)
    setTimeout(() => { handleCloseCopyLinkSuccess() }, 1500)
  }

  const handleCloseCopyLinkSuccess = () => {
    setShowCopyLinkSuccess(false)
  }

  function copyShareLinkToClipboard() {
    navigator.clipboard.writeText(props.share.url)
    handleShowCopyLinkSuccess()
  }

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.actions.handleShareSheetClose}>
        <div className="flex items-center justify-center min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="inline-block max-w-xl bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
              <div className="bg-white p-6">
                <div className="p-2">
                  <div>
                    <Dialog.Title as="h3" className="text-3xl font-semibold text-gray-900 mb-4">
                      Share
                    </Dialog.Title>
                    <div className="flex items-center mb-8">
                      <img
                        className="mr-3 h-14 w-14 rounded-full border-2 border-black"
                        src={props.share.avatar}
                        alt=""
                      />
                      <div>
                        <h1 htmlFor="remember-me" className="font-semibold text-xl">
                          {props.share.name}
                        </h1>
                        <h2 htmlFor="remember-me" className="font-medium text-md text-gray-600">
                          {props.share.school}
                        </h2>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center ">
                        <h2 className="text-lg font-medium">{showCopyLinkSuccess ? "Link copied!" : "Copy link"}</h2>
                      </div>
                      <div className="p-4 overflow-clip border rounded-lg border-gray-200 flex items-center justify-center w-full">
                        <p className="truncate text-gray-500 whitespace-nowrap">{props.share.url}</p>
                        <button className="font-medium ml-2" onClick={copyShareLinkToClipboard}>Copy</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TutorProfileShareModal
