import TutorProfileSubjectRow from "./TutorProfileSubjectRow"

const TutorProfileSubjectSection = (props) => {
  return (
    <div>
      <h2 className="font-bold block text-2xl mb-4">Subjects</h2>
      <div className="space-y-6">
        {props.subjects.map((subject, index) => <TutorProfileSubjectRow key={subject.id} subject={subject} />)}
      </div>
    </div>
  )
}

export default TutorProfileSubjectSection
