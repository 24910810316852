const TutorProfileHeaderSection = (props) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <img
          className="mr-4 h-24 w-24 rounded-full border-4 border-black"
          src={props.info.avatar}
          alt=""
        />
        <div>
          <h1 htmlFor="remember-me" className="ml-2 font-semibold block text-4xl">
            {props.info.name}
          </h1>
          <h2 htmlFor="remember-me" className="ml-2 block font-medium text-lg text-gray-600">
            {props.info.school}
          </h2>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <button className="bg-black border-2 border-transparent hover:bg-gray-800 hover:text-gray-200 text-white font-medium py-2 px-4 rounded inline-flex items-center" onClick={props.actions.handleOpenRequestLessonLink}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
          </svg>
          <span>Request a lesson</span>
        </button>
        <button className="hover:border-gray-600 hover:text-gray-600 border-2 border-black font-medium py-2 px-4 rounded inline-flex items-center" onClick={props.actions.handleShowShareSheet}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
          </svg>
          <span>Share profile</span>
        </button>
      </div>
    </div>
  )
}

export default TutorProfileHeaderSection
