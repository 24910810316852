import { Helmet } from "react-helmet"

const Home = () => {
  return (
    <div className="mx-8 mb-24">
      <Helmet
        title={"Peer | Get tailored help with your coursework"}
        htmlAttributes={{ lang: "en" }}
        meta={[
          {
            name: "description",
            content: "Pay as you go tutoring from students who have already aced the course.",
          },
          {
            property: "og:url",
            content: `${window.location.href}`
          },
          {
            property: "og:title",
            content: "Get tailored help with your coursework"
          },
          {
            property: "og:description",
            content: "Pay as you go tutoring from students who have already aced the course."
          },
          {
            property: "og:type",
            content: "website"
          }
        ]}
      />
      <div className="flex h-screen items-center justify-center">
        <div className="max-w-lg space-y-2">
          <h1 className="text-5xl font-medium">Get tailored help with your coursework</h1>
          <h1 className="text-lg text-gray-600 font-medium">Pay as you go tutoring from students who have already aced the course.</h1>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="space-y-48 max-w-3xl">
          <div>
            <h1 className="text-3xl font-medium mb-16">How Peer works</h1>
            <div className="sm:flex sm:space-x-8 space-y-12 sm:space-y-0">
              <div className="flex-1 space-y-6">
                <svg width="100" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M500 250C500 388.071 388.071 500 250 500C111.929 500 0 388.071 0 250C0 111.929 111.929 0 250 0C388.071 0 500 111.929 500 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M450 250C450 360.457 360.457 450 250 450C139.543 450 50 360.457 50 250C50 139.543 139.543 50 250 50C360.457 50 450 139.543 450 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M400 250C400 332.843 332.843 400 250 400C167.157 400 100 332.843 100 250C100 167.157 167.157 100 250 100C332.843 100 400 167.157 400 250Z" fill="black" fill-opacity="0.1" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M234 202.006C225.513 202.006 217.374 205.378 211.373 211.379C205.371 217.38 202 225.519 202 234.006C202 242.493 205.371 250.632 211.373 256.634C217.374 262.635 225.513 266.006 234 266.006C242.487 266.006 250.626 262.635 256.627 256.634C262.629 250.632 266 242.493 266 234.006C266 225.519 262.629 217.38 256.627 211.379C250.626 205.378 242.487 202.006 234 202.006ZM186 234.006C185.999 226.452 187.781 219.004 191.201 212.268C194.622 205.532 199.583 199.699 205.683 195.242C211.783 190.786 218.848 187.832 226.305 186.621C233.762 185.41 241.399 185.976 248.596 188.273C255.792 190.57 262.345 194.533 267.721 199.84C273.097 205.148 277.145 211.649 279.535 218.815C281.925 225.982 282.589 233.611 281.475 241.083C280.36 248.554 277.497 255.657 273.12 261.814L311.656 300.35C313.113 301.859 313.92 303.88 313.901 305.977C313.883 308.075 313.042 310.081 311.559 311.565C310.075 313.048 308.069 313.889 305.971 313.908C303.874 313.926 301.853 313.119 300.344 311.662L261.816 273.134C254.635 278.24 246.187 281.271 237.397 281.895C228.608 282.519 219.817 280.711 211.986 276.67C204.156 272.629 197.589 266.51 193.006 258.985C188.422 251.46 185.998 242.818 186 234.006Z" fill="black" />
                </svg>
                <div className="space-y-2">
                  <h3 className="font-semibold">Search by subject</h3>
                  <p className="text-sm font-medium text-gray-600">Find a list of tutors by subject and filter by professor, price, and rating</p>
                </div>
              </div>
              <div className="flex-1 space-y-6">
                <svg width="100" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M500 250C500 388.071 388.071 500 250 500C111.929 500 0 388.071 0 250C0 111.929 111.929 0 250 0C388.071 0 500 111.929 500 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M450 250C450 360.457 360.457 450 250 450C139.543 450 50 360.457 50 250C50 139.543 139.543 50 250 50C360.457 50 450 139.543 450 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M400 250C400 332.843 332.843 400 250 400C167.157 400 100 332.843 100 250C100 167.157 167.157 100 250 100C332.843 100 400 167.157 400 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M170 200C170 194.696 172.107 189.609 175.858 185.858C179.609 182.107 184.696 180 190 180H260C265.304 180 270.391 182.107 274.142 185.858C277.893 189.609 280 194.696 280 200V240C280 245.304 277.893 250.391 274.142 254.142C270.391 257.893 265.304 260 260 260H240L210 290V260H190C184.696 260 179.609 257.893 175.858 254.142C172.107 250.391 170 245.304 170 240V200Z" fill="black" />
                  <path d="M300.005 220V240C300.005 250.609 295.791 260.783 288.289 268.284C280.788 275.786 270.614 280 260.005 280H248.285L230.625 297.67C233.425 299.16 236.615 300 240.005 300H260.005L290.005 330V300H310.005C315.309 300 320.396 297.893 324.147 294.142C327.898 290.391 330.005 285.304 330.005 280V240C330.005 234.696 327.898 229.609 324.147 225.858C320.396 222.107 315.309 220 310.005 220H300.005Z" fill="black" />
                </svg>
                <div className="space-y-2">
                  <h3 className="font-semibold">Contact a tutor</h3>
                  <p className="text-sm font-medium text-gray-600">Start chatting with a tutor that fits your criteria and learning style by requesting a lesson from them</p>
                </div>
              </div>
              <div className="flex-1 space-y-6">
                <svg width="100" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M500 250C500 388.071 388.071 500 250 500C111.929 500 0 388.071 0 250C0 111.929 111.929 0 250 0C388.071 0 500 111.929 500 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M450 250C450 360.457 360.457 450 250 450C139.543 450 50 360.457 50 250C50 139.543 139.543 50 250 50C360.457 50 450 139.543 450 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M400 250C400 332.843 332.843 400 250 400C167.157 400 100 332.843 100 250C100 167.157 167.157 100 250 100C332.843 100 400 167.157 400 250Z" fill="black" fill-opacity="0.1" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M218 186C215.878 186 213.843 186.843 212.343 188.343C210.843 189.843 210 191.878 210 194V202H202C197.757 202 193.687 203.686 190.686 206.686C187.686 209.687 186 213.757 186 218V298C186 302.243 187.686 306.313 190.686 309.314C193.687 312.314 197.757 314 202 314H298C302.243 314 306.313 312.314 309.314 309.314C312.314 306.313 314 302.243 314 298V218C314 213.757 312.314 209.687 309.314 206.686C306.313 203.686 302.243 202 298 202H290V194C290 191.878 289.157 189.843 287.657 188.343C286.157 186.843 284.122 186 282 186C279.878 186 277.843 186.843 276.343 188.343C274.843 189.843 274 191.878 274 194V202H226V194C226 191.878 225.157 189.843 223.657 188.343C222.157 186.843 220.122 186 218 186V186ZM218 226C215.878 226 213.843 226.843 212.343 228.343C210.843 229.843 210 231.878 210 234C210 236.122 210.843 238.157 212.343 239.657C213.843 241.157 215.878 242 218 242H282C284.122 242 286.157 241.157 287.657 239.657C289.157 238.157 290 236.122 290 234C290 231.878 289.157 229.843 287.657 228.343C286.157 226.843 284.122 226 282 226H218Z" fill="black" />
                </svg>
                <div className="space-y-2">
                  <h3 className="font-semibold">Start learning</h3>
                  <p className="text-sm font-medium text-gray-600">Schedule an in-person or online lesson to start making progress towards your education goals</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-3xl font-medium mb-16">Why use a student as a tutor?</h1>
            <div className="sm:flex sm:space-x-8 space-y-12 sm:space-y-0">
              <div className="flex-1 space-y-6">
                <svg width="100" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M500 250C500 388.071 388.071 500 250 500C111.929 500 0 388.071 0 250C0 111.929 111.929 0 250 0C388.071 0 500 111.929 500 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M450 250C450 360.457 360.457 450 250 450C139.543 450 50 360.457 50 250C50 139.543 139.543 50 250 50C360.457 50 450 139.543 450 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M400 250C400 332.843 332.843 400 250 400C167.157 400 100 332.843 100 250C100 167.157 167.157 100 250 100C332.843 100 400 167.157 400 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M280 185C280 192.956 276.839 200.587 271.213 206.213C265.587 211.839 257.956 215 250 215C242.044 215 234.413 211.839 228.787 206.213C223.161 200.587 220 192.956 220 185C220 177.044 223.161 169.413 228.787 163.787C234.413 158.161 242.044 155 250 155C257.956 155 265.587 158.161 271.213 163.787C276.839 169.413 280 177.044 280 185V185ZM330 205C330 210.304 327.893 215.391 324.142 219.142C320.391 222.893 315.304 225 310 225C304.696 225 299.609 222.893 295.858 219.142C292.107 215.391 290 210.304 290 205C290 199.696 292.107 194.609 295.858 190.858C299.609 187.107 304.696 185 310 185C315.304 185 320.391 187.107 324.142 190.858C327.893 194.609 330 199.696 330 205V205ZM290 275C290 264.391 285.786 254.217 278.284 246.716C270.783 239.214 260.609 235 250 235C239.391 235 229.217 239.214 221.716 246.716C214.214 254.217 210 264.391 210 275V305H290V275ZM210 205C210 210.304 207.893 215.391 204.142 219.142C200.391 222.893 195.304 225 190 225C184.696 225 179.609 222.893 175.858 219.142C172.107 215.391 170 210.304 170 205C170 199.696 172.107 194.609 175.858 190.858C179.609 187.107 184.696 185 190 185C195.304 185 200.391 187.107 204.142 190.858C207.893 194.609 210 199.696 210 205V205ZM310 305V275C310.014 264.833 307.433 254.83 302.5 245.94C306.933 244.805 311.568 244.698 316.049 245.627C320.53 246.556 324.74 248.496 328.357 251.299C331.974 254.102 334.903 257.695 336.921 261.802C338.939 265.91 339.992 270.424 340 275V305H310ZM197.5 245.94C192.567 254.83 189.986 264.833 190 275V305H160V275C159.998 270.42 161.045 265.901 163.059 261.789C165.074 257.676 168.004 254.079 171.624 251.274C175.244 248.469 179.458 246.53 183.943 245.605C188.428 244.681 193.066 244.795 197.5 245.94V245.94Z" fill="black" />
                </svg>
                <div className="space-y-2">
                  <h3 className="font-semibold">Relatable</h3>
                  <p className="text-sm font-medium text-gray-600">These are not your run-of-the-mill tutors. Peer tutors have aced your exact course and want to share what they learned with you.</p>
                </div>
              </div>
              <div className="flex-1 space-y-6">
                <svg width="100" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M500 250C500 388.071 388.071 500 250 500C111.929 500 0 388.071 0 250C0 111.929 111.929 0 250 0C388.071 0 500 111.929 500 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M450 250C450 360.457 360.457 450 250 450C139.543 450 50 360.457 50 250C50 139.543 139.543 50 250 50C360.457 50 450 139.543 450 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M400 250C400 332.843 332.843 400 250 400C167.157 400 100 332.843 100 250C100 167.157 167.157 100 250 100C332.843 100 400 167.157 400 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M240 170C237.348 170 234.804 171.054 232.929 172.929C231.054 174.804 230 177.348 230 180C230 182.652 231.054 185.196 232.929 187.071C234.804 188.946 237.348 190 240 190H260C262.652 190 265.196 188.946 267.071 187.071C268.946 185.196 270 182.652 270 180C270 177.348 268.946 174.804 267.071 172.929C265.196 171.054 262.652 170 260 170H240Z" fill="black" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M190 200C190 194.696 192.107 189.609 195.858 185.858C199.609 182.107 204.696 180 210 180C210 187.956 213.161 195.587 218.787 201.213C224.413 206.839 232.044 210 240 210H260C267.956 210 275.587 206.839 281.213 201.213C286.839 195.587 290 187.956 290 180C295.304 180 300.391 182.107 304.142 185.858C307.893 189.609 310 194.696 310 200V310C310 315.304 307.893 320.391 304.142 324.142C300.391 327.893 295.304 330 290 330H210C204.696 330 199.609 327.893 195.858 324.142C192.107 320.391 190 315.304 190 310V200ZM287.07 257.07C288.892 255.184 289.9 252.658 289.877 250.036C289.854 247.414 288.802 244.906 286.948 243.052C285.094 241.198 282.586 240.146 279.964 240.123C277.342 240.1 274.816 241.108 272.93 242.93L240 275.86L227.07 262.93C225.184 261.108 222.658 260.1 220.036 260.123C217.414 260.146 214.906 261.198 213.052 263.052C211.198 264.906 210.146 267.414 210.123 270.036C210.1 272.658 211.108 275.184 212.93 277.07L232.93 297.07C234.805 298.945 237.348 299.998 240 299.998C242.652 299.998 245.195 298.945 247.07 297.07L287.07 257.07V257.07Z" fill="black" />
                </svg>
                <div className="space-y-2">
                  <h3 className="font-semibold">Convenient</h3>
                  <p className="text-sm font-medium text-gray-600">Manage communication, scheduling, and payment all through Peer. This leaves you free to spend time on what is most important. Learning.</p>
                </div>
              </div>
              <div className="flex-1 flex-1 space-y-6">
                <svg width="100" height="100" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M500 250C500 388.071 388.071 500 250 500C111.929 500 0 388.071 0 250C0 111.929 111.929 0 250 0C388.071 0 500 111.929 500 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M450 250C450 360.457 360.457 450 250 450C139.543 450 50 360.457 50 250C50 139.543 139.543 50 250 50C360.457 50 450 139.543 450 250Z" fill="black" fill-opacity="0.1" />
                  <path d="M400 250C400 332.843 332.843 400 250 400C167.157 400 100 332.843 100 250C100 167.157 167.157 100 250 100C332.843 100 400 167.157 400 250Z" fill="black" fill-opacity="0.1" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M175 175C168.37 175 162.011 177.634 157.322 182.322C152.634 187.011 150 193.37 150 200V250C150 256.63 152.634 262.989 157.322 267.678C162.011 272.366 168.37 275 175 275V200H300C300 193.37 297.366 187.011 292.678 182.322C287.989 177.634 281.63 175 275 175H175ZM200 250C200 243.37 202.634 237.011 207.322 232.322C212.011 227.634 218.37 225 225 225H325C331.63 225 337.989 227.634 342.678 232.322C347.366 237.011 350 243.37 350 250V300C350 306.63 347.366 312.989 342.678 317.678C337.989 322.366 331.63 325 325 325H225C218.37 325 212.011 322.366 207.322 317.678C202.634 312.989 200 306.63 200 300V250ZM275 300C281.63 300 287.989 297.366 292.678 292.678C297.366 287.989 300 281.63 300 275C300 268.37 297.366 262.011 292.678 257.322C287.989 252.634 281.63 250 275 250C268.37 250 262.011 252.634 257.322 257.322C252.634 262.011 250 268.37 250 275C250 281.63 252.634 287.989 257.322 292.678C262.011 297.366 268.37 300 275 300Z" fill="black" />
                </svg>
                <div className="space-y-2">
                  <h3 className="font-semibold">Affordable</h3>
                  <p className="text-sm font-medium text-gray-600">Tutors are expensive. Tutors on Peer cost roughly 1/3 the national average.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Home
