const Footer = () => {
  return (
    <footer className="bg-black pt-8">
      <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-left">
        <div className="p-5 w-1/2 sm:w-4/12 md:w-4/12">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            About
          </div>
          <a href="/support" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Support
          </a>
          <a href="/privacy-policy" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Privacy Policy
          </a>
          <a href="/terms-of-use" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Terms of Use
          </a>
        </div>
        <div className="p-5 w-1/2 sm:w-4/12 md:w-4/12">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Students
          </div>
          <a href="https://apps.apple.com/us/app/peer-find-a-tutor/id1583986062" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Find a Tutor
          </a>
        </div>
        <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Tutors
          </div>
          <a href="https://apps.apple.com/us/app/peer-for-tutors/id1583984688" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
            Become a Tutor
          </a>
        </div>
      </div>
      <div className="pt-2">
        <div className="flex px-3 py-6 m-auto border-t border-gray-500 text-gray-400 text-sm flex-col md:flex-row max-w-6xl">
          <div className="mt-2">
            © 2021 Sofic Inc.
          </div>
          <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
            <a href="https://www.facebook.com/peer.sofic/" className="text-gray-200">
              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a href="https://twitter.com/peer_sofic" className="ml-3 text-gray-200">
              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a href="https://www.instagram.com/peer.sofic/" className="ml-3 text-gray-200">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/peer-sofic/" className="ml-3 text-gray-200">
              <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
