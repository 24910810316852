const ZoomSuccess = () => {
  return (
    <div className="flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6 m-6">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src="/assets/icon.dark.svg"
            alt="Workflow"
          />
          <div className="py-8">
            <h3 className="text-center text-lg font-bold text-gray-800">Stripe Connected</h3>
            <h2 className="text-center text-3xl font-extrabold text-gray-900">Success</h2>
          </div>
          <p className="text-center font-medium text-gray-600">
            Please return to the app
          </p>
        </div>
      </div>
    </div>
  )
}

export default ZoomSuccess