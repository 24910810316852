const TutorProfileSubjectRow = (props) => {
  return (
    <div className="space-y-0">
      <div className="space-y-0">
        <h1 className="text-lg font-semibold">{props.subject.number} at ${props.subject.hourlyRate} per hour</h1>
        <h2 className="text-lg text-gray-800">{props.subject.title} with {props.subject.professor}</h2>
      </div>
      <p>{props.subject.description}</p>
    </div>
  )
}

export default TutorProfileSubjectRow
