import TutorProfileReviewRow from "./TutorProfileReviewRow"

const TutorProfileReviewSection = (props) => {
  return (
    <div>
      <h2 className="font-bold block text-2xl mb-4">Reviews</h2>
      <div className="space-y-6">
        {props.reviews.map((review, index) => <TutorProfileReviewRow key={review.id} review={review} />)}
      </div>
    </div>
  )
}

export default TutorProfileReviewSection
