import { StarIcon, ClockIcon, UserGroupIcon, ShieldCheckIcon } from "@heroicons/react/outline"

const TutorProfileOverviewSection = (props) => {
  return (
    <div>
      <h2 className="font-bold block text-2xl mb-4">Overview</h2>
      <div className="space-y-4 font-medium">
        <div className="flex items-center space-x-4">
          <StarIcon className="block h-6 w-6" aria-hidden="true" />
          <p>{props.overview.averageRating}</p>
        </div>
        <div className="flex items-center space-x-4">
          <ClockIcon className="block h-6 w-6" aria-hidden="true" />
          <p>{props.overview.minutesTutored}</p>
        </div>
        <div className="flex items-center space-x-4">
          <UserGroupIcon className="block h-6 w-6" aria-hidden="true" />
          <p>{props.overview.studentsTutored}</p>
        </div>
        <div className="flex items-center space-x-4">
          <ShieldCheckIcon className="block h-6 w-6" aria-hidden="true" />
          <p>Identity verified</p>
        </div>
      </div>
    </div>
  )
}

export default TutorProfileOverviewSection
