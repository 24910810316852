const TutorProfileAboutSection = (props) => {
  return (
    <div>
      <h2 className="font-bold block text-2xl mb-4">About</h2>
      <p>{props.about.bio}</p>
    </div>
  )
}

export default TutorProfileAboutSection
