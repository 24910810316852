import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const app = initializeApp({
  apiKey: "AIzaSyClNgTSCxdfepF3-kyoJcVsiZo9bD4W9ww",
  authDomain: "sofic-peer-app.firebaseapp.com",
  projectId: "sofic-peer-app",
  storageBucket: "sofic-peer-app.appspot.com",
  messagingSenderId: "767921287237",
  appId: "1:767921287237:web:f6c39d928c1467d3e9b32c",
  measurementId: "G-KX196L17P8"
})

const auth = getAuth()
const firestore = getFirestore()
const storage = getStorage(app)

export default firestore
export { auth, storage }