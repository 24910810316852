import { BrowserRouter, Switch, Route } from "react-router-dom"

import Footer from "./components/footer/Footer"
import Home from "./components/home/Home"
import NavigationBar from "./components/navigation-bar/NavigationBar"
import PrivacyPolicy from "./components/pages/PrivacyPolicy"
import StripeSuccess from "./components/stripe-success/StripeSuccess"
import TermsOfUse from "./components/pages/TermsOfUse"
import TutorProfile from "./components/tutor-profile/TutorProfile"
import ZoomDocumentation from "./components/pages/ZoomDocumentation"
import ZoomSuccess from "./components/zoom-success/ZoomSuccess"

const App = () => {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/stripe-success" component={StripeSuccess} />
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route exact path="/zoom-documentation" component={ZoomDocumentation} />
        <Route exact path="/zoom-success" component={ZoomSuccess} />
        <Route path="/tutors/:tutorID" component={TutorProfile} />
        <Route path="/links" component={() => { window.location.replace("https://apps.apple.com/in/app/peer-find-a-tutor/id1583986062"); return null }} />
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}

export default App